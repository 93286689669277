import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmPopup, ConfirmPopup } from "primereact/confirmpopup";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { RadioButton } from "primereact/radiobutton";
import { Calendar } from "primereact/calendar";
import { Tooltip } from "primereact/tooltip";
import { Paginator } from "primereact/paginator";

import classNames from "classnames";

import { Tag } from "primereact/tag";
import { addDateTime } from "../../../common/date-time";

import "./GARemoteCommandCreateJob.scss";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";

import {
	getGaRemoteCommandDivisionDetailsInit,
	postCreateJobInit,
	resetCloneJobDetails,
	resetCreateJobSuccessMessage,
	resetModifyJobDetails,
} from "../redux/actions";

import { Chip } from "primereact/chip";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Dialog } from "primereact/dialog";

const GARemoteCommandCreateJob = () => {
	const modifyJobDetails = useSelector(
		(state) => state.gaRemoteCommand.setModifyJobDetails
	);

	const cloneJobDetails = useSelector(
		(state) => state.gaRemoteCommand.setCloneJobDetails
	);

	const history = useHistory();

	const toastRef = useRef();
	const createJobBtnRef = useRef(null);

	const serviceList = [
		{ label: "grafana-agent", value: "grafana-agent" },
		{
			label: "grafana-agent-linux",
			value: "grafana-agent-linux",
		},
		{
			label: "grafana-agent-windows",
			value: "grafana-agent-windows",
		},
	];

	const [availableServices, setAvailableServices] = useState(serviceList);

	const serverPerLine = 5;

	const [firstTimeRendering, setFirstTimeRendering] = useState(false);

	const [division, setDivision] = useState(null); //dividion dropdown

	const [storeSelectionList, setStoreSelectionList] = useState(null); //render store
	const [service, setService] = useState(); //multiselect

	const [selectedServerType, setSelectedServerType] = useState(null); //multiselect
	const [selectedDateTime, setSelectedDateTime] = useState(null);

	const [selectedCommand, setSelectedCommand] = useState("Start");
	const [ritNumber, setRitNumber] = useState("");
	const [forceUpdate, setForceUpdate] = useState(false);
	const [selectedJobInfo, setSelectedJobInfo] = useState(null);
	const [showExistingJobInfoDialog, setShowExistingJobInfoDialog] =
		useState(false);
	const [showConfirmPopUp, setShowConfirmPopUp] = useState(false);

	const [jobPayload, setJobPayload] = useState(null);

	const [enteredStoreNumber, setEnteredStoreNumber] = useState(null);
	const [jobId, setJobId] = useState(null);
	const [jobType, setJobType] = useState(null);
	const [isModelContainerExpanded, setIsModelContainerExpanded] =
		useState(true);
	const [legendContainer, setLegendContainer] = useState(false);
	const [first, setFirst] = useState(0);
	const [rows, setRows] = useState(20);

	const storeRowRefs = useRef({});
	const searchInputRef = useRef(null);

	const divisionList = useSelector(
		(state) => state.gaRemoteCommand.divisionDetails.divStoreServerList
	);

	const serverTypeList = useSelector(
		(state) => state.gaRemoteCommand.divisionDetails.serverTypes
	); //multiselect

	const allServers = useSelector(
		(state) => state.gaRemoteCommand.divisionDetails.serverTypes
	); //on all servers (default)

	const dispatch = useDispatch();
	const createJobSuccessMessage = useSelector(
		(state) => state.gaRemoteCommand.createJobSuccessMessage
	);

	/**
	 * Get Division and serverType details
	 */
	console.log(
		"Division:",
		division,
		"StoreSelectionList:",
		storeSelectionList,
		"SelectedServerType:",
		selectedServerType,
		"Service:",
		service,
		"SelectedCommand:",
		selectedCommand,
		"Job type:",
		jobType
	);
	useEffect(() => {
		if (!divisionList && !serverTypeList) {
			dispatch(getGaRemoteCommandDivisionDetailsInit());
		} else {
			setDivision([...divisionList]);
			setFirstTimeRendering(true);
		}
	}, [divisionList, serverTypeList, dispatch]);

	useEffect(() => {
		if (division && !modifyJobDetails && !cloneJobDetails) {
			fetchStores();
		}
	}, [division, dispatch]);

	useEffect(() => {
		if (firstTimeRendering && !modifyJobDetails && !cloneJobDetails) {
			fetchStores();
		}
		if (firstTimeRendering && !!modifyJobDetails) {
			const {
				jobId,
				jobType,
				division,
				selectedStore,
				serverTypes,
				serviceTypes,
				command,
				scheduledDateTime,
				ritNumber,
			} = modifyJobDetails;

			if (selectedStore && selectedStore.length > 0) {
				setJobId(jobId);

				const divisions = division.split(",");

				const selectedDivision = divisionList.filter((div) =>
					divisions.includes(div.division)
				);

				setDivision(selectedDivision);

				const storeList = {};
				selectedDivision.forEach((divinfo) => {
					if (
						!!divinfo.storeServerList &&
						divinfo.storeServerList.length > 0
					) {
						divinfo.storeServerList.forEach((storeInfo) => {
							const store = storeInfo.store;
							if (!storeList[store]) {
								storeList[store] = {
									division: divinfo.division,
									isSelected: selectedStore.includes(store),
									includedServerType:
										storeInfo.includedServerTypes || [],
									excludedServerType:
										storeInfo.excludedServerTypes || [],
								};
							} else {
								storeList[store].isSelected =
									selectedStore.includes(store);
							}
						});
					}
				});

				setStoreSelectionList(storeList);
			}
			setSelectedServerType(serverTypes);

			setService(serviceTypes);

			setForceUpdate(false);
			setJobType(jobType);

			setSelectedDateTime(new Date(scheduledDateTime));

			setSelectedCommand(command);
			setRitNumber(ritNumber);
		}
		if (firstTimeRendering && !!cloneJobDetails) {
			const {
				division,
				selectedStore,
				serverTypes,
				serviceTypes,
				command,
			} = cloneJobDetails;

			const divisions = division.split(",");

			const selectedDivision = divisionList.filter((div) =>
				divisions.includes(div.division)
			);

			setDivision(selectedDivision);

			const storeList = {};
			selectedDivision.forEach((divinfo) => {
				if (
					!!divinfo.storeServerList &&
					divinfo.storeServerList.length > 0
				) {
					divinfo.storeServerList.forEach((storeInfo) => {
						const store = storeInfo.store;
						if (!storeList[store]) {
							storeList[store] = {
								division: divinfo.division,
								isSelected: selectedStore.includes(store),
								includedServerType:
									storeInfo.includedServerTypes || [],
								excludedServerType:
									storeInfo.excludedServerTypes || [],
							};
						} else {
							storeList[store].isSelected =
								selectedStore.includes(store);
						}
					});
				}
			});

			setStoreSelectionList(storeList);

			setSelectedServerType(serverTypes);

			setService(serviceTypes);
			setSelectedCommand(command);
		}
	}, [
		firstTimeRendering,
		// division,
		modifyJobDetails,
		cloneJobDetails,
		dispatch,
	]);

	useEffect(() => {
		if (selectedServerType && selectedServerType.includes("d")) {
			setAvailableServices(serviceList);
		} else {
			setAvailableServices([serviceList[0]]);
			setService([serviceList[0].value]);
		}
	}, [selectedServerType]);

	const navigateToJobDetails = () => {
		setTimeout(() => {
			history.push("/admin/grafana-agent-remote-command/job-details");
		}, 3000);
	};

	/**
 Get Stores based on division
 */
	const fetchStores = () => {
		const storeList = {};
		if (division && division.length > 0) {
			division.forEach((divinfo) => {
				if (
					!!divinfo.storeServerList &&
					divinfo.storeServerList.length > 0
				) {
					divinfo.storeServerList.forEach((storeInfo) => {
						const store = storeInfo.store;
						if (!storeList[store]) {
							storeList[store] = {
								division: divinfo.division,
								isSelected: false,
								includedServerType:
									storeInfo.includedServerTypes || [],
								excludedServerType:
									storeInfo.excludedServerTypes || [],
							};
						}
					});
				}
			});
		}
		setStoreSelectionList(storeList);
	};

	/**
	 *
	 * Update selection for all stores at once
	 * @param {boolean} value - true/false
	 */
	const handleAllStoreSelection = (value) => {
		const storeList = { ...storeSelectionList };
		Object.keys(storeList).forEach((store) => {
			storeList[store].isSelected = value;
		});

		setStoreSelectionList({ ...storeList });
		if (!value) {
			setSelectedServerType(null);
			setEnteredStoreNumber(null);
		}
	};

	/**
	 * Select/Unselect store on clicking
	 * @param {string} store - Store Number
	 */
	const handleStoreButtonClick = (store) => {
		setStoreSelectionList((prevStoreList) => {
			const storeList = { ...prevStoreList };
			storeList[store] = {
				...storeList[store],
				isSelected: !prevStoreList[store].isSelected,
			};

			//Clear the entered number when the store is selected
			if (storeList[store].isSelected) {
				setEnteredStoreNumber(null);
			}
			//clear selected server types only of the last store is being unselected

			if (
				!storeList[store].isSelected &&
				Object.keys(storeList).filter(
					(store) => storeList[store].isSelected
				).length === 0
			) {
				setSelectedServerType(null);
			}
			return storeList;
		});
	};

	useEffect(() => {
		if (createJobSuccessMessage) {
			if (
				createJobSuccessMessage.includes(
					"Created scheduled job successfully"
				)
			) {
				toastRef.current.show({
					severity: "success",
					summary: "Success",
					detail: "Job created successfully.",
					life: 3000,
				});

				dispatch(resetCreateJobSuccessMessage());
				dispatch(resetCloneJobDetails());
				resetSelections();
				navigateToJobDetails();
			} else if (
				createJobSuccessMessage.includes("Modified job successfully")
			) {
				toastRef.current.show({
					severity: "success",
					summary: "Success",
					detail: "Job modified successfully.",
					life: 3000,
				});
				dispatch(resetCreateJobSuccessMessage());
				dispatch(resetModifyJobDetails());
				resetSelections();
				navigateToJobDetails();
			} else if (
				createJobSuccessMessage.includes("Job Excecution started")
			) {
				toastRef.current.show({
					severity: "success",
					summary: "Success",
					detail: "Job execution started .",
					life: 3000,
				});
				dispatch(resetCreateJobSuccessMessage());
				dispatch(resetCloneJobDetails());
				resetSelections();
				navigateToJobDetails();
			} else if (createJobSuccessMessage.includes("Job Accepted")) {
				toastRef.current.show({
					severity: "success",
					summary: "Success",
					detail: "Job Accepted.",
					life: 3000,
				});
				dispatch(resetCreateJobSuccessMessage());
				dispatch(resetCloneJobDetails());
				resetSelections();
				navigateToJobDetails();
			} else {
				const jobIds = createJobSuccessMessage.map(
					(job) => (job.jobData.match(/job-(\d+)/) || [])[0]
				);
				confirmPopup({
					target: createJobBtnRef.current,
					message: (
						<div>
							<div>Job already exists.</div>
							{jobIds.length > 0 && (
								<div>
									Job Ids:{" "}
									{jobIds.map((jobId, index) => (
										<React.Fragment key={jobId}>
											<a
												href={`#${jobId}`}
												onClick={(e) => {
													e.preventDefault();
													const selectedJob =
														createJobSuccessMessage.find(
															(job) =>
																job.jobData.includes(
																	jobId
																)
														);
													setSelectedJobInfo(
														selectedJob
													);
													// setClickedJobId(jobId);
													setShowExistingJobInfoDialog(
														true
													);
													setShowConfirmPopUp(false);
												}}
											>
												{jobId}
											</a>
											{index < jobIds.length - 1
												? ", "
												: ""}
										</React.Fragment>
									))}
								</div>
							)}
							<div>Do you still wish to continue?</div>
						</div>
					),
					accept: () => {
						dispatch(
							postCreateJobInit({
								...jobPayload,
								forceUpdate: true,
							})
						);

						dispatch(resetCreateJobSuccessMessage());
						setShowConfirmPopUp(false);
					},
					reject: () => {
						resetSelections();
						dispatch(resetCreateJobSuccessMessage());
						dispatch(resetModifyJobDetails());
						dispatch(resetCloneJobDetails());
						setShowConfirmPopUp(false);
					},
				});
			}
		}
	}, [createJobSuccessMessage, dispatch]);

	const resetSelections = () => {
		setSelectedServerType(null);
		setService(null);
		setAvailableServices(null);
		setSelectedCommand(null);
		setSelectedDateTime(null);
		setDivision([...divisionList]);
		setEnteredStoreNumber(null);
		setJobType(null);
		setRitNumber("");

		const resetStoreSelectionList = { ...storeSelectionList };
		Object.keys(resetStoreSelectionList).forEach((store) => {
			resetStoreSelectionList[store].isSelected = false;
		});
		setStoreSelectionList(resetStoreSelectionList);
	};

	const handleCreateJob = async (e) => {
		//validation checks

		if (!division) {
			toastRef.current.show({
				severity: "error",
				summary: "Error",
				detail: "Please select division.",
				life: 3000,
			});
			return;
		}

		if (
			!Object.keys(storeSelectionList || {}).some(
				(store) => storeSelectionList[store].isSelected
			)
		) {
			toastRef.current.show({
				severity: "error",
				summary: "Error",
				detail: "Please select store(s).",
				life: 3000,
			});
			return;
		}

		if (!selectedServerType || selectedServerType.length === 0) {
			toastRef.current.show({
				severity: "error",
				summary: "Error",
				detail: "Please select server type(s).",
				life: 3000,
			});
			return;
		}

		if (!service || service.length === 0) {
			toastRef.current.show({
				severity: "error",
				summary: "Error",
				detail: "Please select service(s).",
				life: 3000,
			});
			return;
		}

		if (!selectedCommand) {
			toastRef.current.show({
				severity: "error",
				summary: "Error",
				detail: "Please select command.",
				life: 3000,
			});
			return;
		}

		if (!selectedDateTime && jobType === "Scheduled") {
			toastRef.current.show({
				severity: "error",
				summary: "Error",
				detail: "Please select date and time.",
				life: 3000,
			});
			return;
		}

		if (jobType === "Execute") {
			setSelectedDateTime(null);
		}

		if (!ritNumber) {
			toastRef.current.show({
				severity: "error",
				summary: "Error",
				detail: "Please enter RIT/INC/Change Number",
				life: 3000,
			});
			return;
		}

		if (
			division &&
			storeSelectionList &&
			selectedServerType &&
			service &&
			selectedCommand &&
			ritNumber
		) {
			const selectedStores = Object.keys(storeSelectionList).filter(
				(store) => storeSelectionList[store].isSelected
			);
			const selectedDivisions = selectedStores.map(
				(store) => storeSelectionList[store].division
			);
			const serverTypes = Array.isArray(selectedServerType)
				? selectedServerType
				: [selectedServerType];
			const payload = {
				jobId: jobId,
				division: [...new Set(selectedDivisions)],
				stores: selectedStores,
				serverTypes: serverTypes,
				services: service,
				command: selectedCommand,
				scheduleDateTime: selectedDateTime,
				forceUpdate: forceUpdate,
				jobType: jobType,
				itsmId: ritNumber,
			};
			dispatch(postCreateJobInit(payload));
			setJobPayload(payload);
		}
	};

	const handleDialogClose = () => {
		setShowExistingJobInfoDialog(false);
		setShowConfirmPopUp(true);
	};

	const handleSearch = () => {
		scrollToShowEnteredStore();
	};

	const scrollToShowEnteredStore = () => {
		let enteredStoreNumberIndex = 0;
		if (!!enteredStoreNumber) {
			Object.keys(storeSelectionList)
				.sort()
				.forEach((store, index) => {
					if (store === enteredStoreNumber) {
						enteredStoreNumberIndex = index;
					}
				});
			setFirst(enteredStoreNumberIndex);
		}
	};

	const handleSearchInputChange = (e) => {
		setEnteredStoreNumber(e.target.value);
	};

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			handleSearch();
		}
	};

	const handleJobTypeChange = (jobType) => {
		setJobType(jobType);

		if (jobType === "Execute") {
			setSelectedDateTime(null);
		}
	};

	const handleToggleModelContainer = () => {
		setIsModelContainerExpanded(!isModelContainerExpanded);
	};

	const onPageChange = (event) => {
		setFirst(event.first);
		setRows(event.rows);
	};

	return (
		<div className="create-job-container">
			<Dialog
				visible={showExistingJobInfoDialog}
				onHide={handleDialogClose}
				style={{ width: "50vw" }}
				header={`${selectedJobInfo?.jobData}`}
			>
				{selectedJobInfo && (
					<div className="server-info-container">
						{selectedJobInfo?.serverData.map(
							(serverEntry, index) => (
								<div key={index} className="server-entry">
									{serverEntry}{" "}
								</div>
							)
						)}
					</div>
				)}
			</Dialog>
			<Dialog
				visible={legendContainer}
				onHide={() => setLegendContainer(false)}
				position="top"
			>
				<div className="color-legend">
					<div className="store-legend">
						<span className="stores-legend-div">
							<span className="stores-legend"></span>
							<span>Unselected Store</span>
						</span>
						<span className="selected-store-div">
							<span className="selected-store-legend"></span>
							<span>Selected Store</span>
						</span>
					</div>
					<div className="server-legend">
						<span className="available-servers-legend-div">
							<span className="available-servers-legend"></span>
							<span>Available Server</span>
						</span>
						<span className="selected-server-div">
							<span className="selected-server-legend"></span>
							<span>Selected Server</span>
						</span>
						<span className="unavailable-servers-div">
							<span className="unavailable-servers-legend"></span>
							<span>
								Unavailable Server (grafana-agent not installed)
							</span>
						</span>
					</div>
				</div>
			</Dialog>
			<div className="dropdown-container">
				<div className="division-dropdown">
					<span>Division</span>
					<MultiSelect
						optionLabel="division"
						options={divisionList}
						value={division}
						onChange={(event) => {
							setDivision(event.value);
							setStoreSelectionList(null);
							setSelectedServerType(null);
						}}
						disabled={!!modifyJobDetails || !!cloneJobDetails}
						placeholder="All"
					/>

					<Link
						to="/admin/grafana-agent-remote-command/legend"
						className="legend-link"
						onClick={() => setLegendContainer(true)}
					>
						Legend
					</Link>
				</div>
			</div>
			<div
				className={`grafana-agent-command-main ${
					isModelContainerExpanded ? "" : "collapsed"
				}`}
			>
				<div className="store-container">
					{storeSelectionList && (
						<>
							<strong>Selected Stores: </strong>
							<span>
								{Object.keys(storeSelectionList)
									.filter(
										(store) =>
											storeSelectionList[store].isSelected
									)
									.slice(0, 9)
									.map((store) => (
										<Chip
											label={store}
											style={{ margin: "0.5rem" }}
										/>
									))}
							</span>
						</>
					)}
					{storeSelectionList &&
						Object.keys(storeSelectionList).filter(
							(store) => storeSelectionList[store].isSelected
						).length >= 10 && (
							<>
								<Tooltip target=".more" />
								<Chip
									label="..."
									className="more"
									data-pr-tooltip={Object.keys(
										storeSelectionList
									)
										.filter(
											(store) =>
												storeSelectionList[store]
													.isSelected
										)
										.slice(9)}
									data-pr-position="right"
								/>
							</>
						)}
					{storeSelectionList && (
						<div className="store-container-top">
							<div className="update-store-selection">
								<Button
									label="Select All Stores"
									onClick={() =>
										handleAllStoreSelection(true)
									}
								/>
								<Button
									label="Reset"
									onClick={() =>
										handleAllStoreSelection(false)
									}
								/>
								<div className="search-store">
									<InputText
										type="text"
										placeholder="Search Store"
										ref={searchInputRef}
										value={enteredStoreNumber || ""}
										onChange={handleSearchInputChange}
										onKeyDown={handleKeyDown}
									/>
									<Button
										label="Search"
										icon="pi pi-search"
										onClick={handleSearch}
										setEnteredStoreNumber={
											enteredStoreNumber
										}
										className="filter-button p-button-outlined p-button-sm"
									/>
								</div>
							</div>
						</div>
					)}

					{storeSelectionList &&
						Object.keys(storeSelectionList)
							.sort()
							.slice(first, first + rows)
							.map((store) => (
								<Button
									key={store}
									ref={(ref) =>
										(storeRowRefs.current[store] = ref)
									}
									label={
										<div>
											<span>{store}</span>
											<div className="server-type-chips">
												{storeSelectionList &&
													allServers.map(
														(server, index) => (
															<React.Fragment
																key={server}
															>
																<Tag
																	className={classNames(
																		"default-servers",
																		storeSelectionList[
																			store
																		].includedServerType.includes(
																			server
																		)
																			? "included-servers"
																			: storeSelectionList[
																					store
																			  ].excludedServerType.includes(
																					server
																			  )
																			? "excluded-servers"
																			: "default-servers",
																		// selectedServerType && storeSelectionList[store].isSelected && selectedServerType.includes(server)
																		// ? "selected-server" : ""
																		selectedServerType &&
																			storeSelectionList[
																				store
																			]
																				.isSelected &&
																			selectedServerType.includes(
																				server
																			) &&
																			!storeSelectionList[
																				store
																			].excludedServerType.includes(
																				server
																			)
																			? "selected-server"
																			: ""
																	)}
																>
																	{server}
																</Tag>
																{(index + 1) %
																	serverPerLine ===
																	0 && <br />}
															</React.Fragment>
														)
													)}
											</div>
										</div>
									}
									onClick={() =>
										handleStoreButtonClick(store)
									}
									className={`store-btn ${
										storeSelectionList[store].isSelected
											? "enabled-store"
											: "default-store"
									} ${
										//bwlow one line only added
										storeSelectionList[store].isSelected
											? "enabled-store"
											: enteredStoreNumber === store
											? "highlighted-searched-store"
											: ""
									}`}
								/>
							))}
					<Paginator
						first={first}
						rows={rows}
						onPageChange={onPageChange}
						totalRecords={
							!!storeSelectionList &&
							Object.keys(storeSelectionList).length
						}
						rowsPerPageOptions={[15, 25, 50]}
					></Paginator>
				</div>

				<div
					className={`model-container ${
						isModelContainerExpanded ? "expanded" : "collapsed"
					}`}
				>
					<div className="service-dropdown">
						<span style={{ fontWeight: "bold", fontSize: "14px" }}>
							Server Type(s){" "}
						</span>

						<MultiSelect
							id="server-types"
							testid="server-types-multiselect"
							value={selectedServerType || []}
							options={serverTypeList}
							data-testid="server-type-multiselect"
							onChange={(e) => setSelectedServerType(e.value)}
							style={{ width: "70%" }}
							placeholder="Select Server Type(s)"
							disabled={Object.keys(
								storeSelectionList || {}
							).every(
								(store) => !storeSelectionList[store].isSelected
							)}
						/>
					</div>
					<div className="service-dropdown">
						<span style={{ fontWeight: "bold", fontSize: "14px" }}>
							Service(s)
						</span>

						<MultiSelect
							id="service"
							options={availableServices}
							value={service || []}
							onChange={(e) => {
								setService(e.value);
							}}
							style={{ width: "70%" }}
							disabled={
								!division ||
								!selectedServerType ||
								!selectedServerType.length > 0 ||
								Object.values(storeSelectionList || {}).every(
									(isSelected) => !isSelected
								)
							}
							placeholder="Select Service(s)"
						/>
					</div>

					<div className="service-dropdown">
						<span style={{ fontWeight: "bold", fontSize: "14px" }}>
							Action
						</span>
						<Dropdown
							optionLabel="action"
							optionValue="action"
							options={[
								{ action: "Start" },
								{ action: "Stop" },
								{ action: "Get Status" },
							]}
							value={selectedCommand}
							style={{ width: "70%" }}
							onChange={(event) => {
								setSelectedCommand(event.value);
							}}
							disabled={
								!division ||
								Object.values(storeSelectionList || {}).every(
									(isSelected) => !isSelected
								) ||
								!selectedServerType ||
								!selectedServerType.length > 0 ||
								!service ||
								!service.length > 0
							}
						/>
					</div>

					<table>
						<thead>
							<tr>
								<th style={{ width: "52%" }}>
									Choose Job Type :
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<RadioButton
										inputId="executeNow"
										name="executionOption"
										value="Execute"
										disabled={
											!division ||
											Object.values(
												storeSelectionList || {}
											).every(
												(isSelected) => !isSelected
											) ||
											!selectedServerType ||
											!selectedServerType.length > 0 ||
											!service ||
											!service.length > 0 ||
											!selectedCommand
										}
										onChange={() =>
											handleJobTypeChange("Execute")
										}
										checked={jobType === "Execute"}
									/>
									<label htmlFor="executeNow">
										Execute Now
									</label>
								</td>

								<td>
									<RadioButton
										inputId="schedule"
										name="executionOption"
										value="Scheduled"
										disabled={
											!division ||
											Object.values(
												storeSelectionList || {}
											).every(
												(isSelected) => !isSelected
											) ||
											!selectedServerType ||
											!service ||
											!service.length > 0 ||
											!selectedCommand
										}
										onChange={(e) => setJobType(e.value)}
										checked={jobType === "Scheduled"}
									/>
									<label htmlFor="schedule">
										Schedule Later
									</label>
									{jobType === "Scheduled" && (
										<Calendar
											// id="time24"
											testid="date-time-picker"
											minDate={
												!modifyJobDetails
													? addDateTime(
															"minutes",
															10,
															new Date()
													  )
													: new Date() ||
													  !cloneJobDetails
													? addDateTime(
															"minutes",
															10,
															new Date()
													  )
													: new Date()
											}
											value={selectedDateTime}
											// className="division-dropdown"
											onChange={(e) =>
												setSelectedDateTime(e.value)
											}
											style={{ paddingTop: "0.5rem" }}
											disabled={
												!division ||
												Object.values(
													storeSelectionList || {}
												).every(
													(isSelected) => !isSelected
												) ||
												!selectedServerType ||
												!service ||
												!service.length > 0 ||
												!selectedCommand
											}
											showIcon
											showTime
										/>
									)}
								</td>
							</tr>
						</tbody>
					</table>

					<div>
						<span style={{ fontWeight: "bold", fontSize: "14px" }}>
							{" "}
							RIT/INC/Change Number{" "}
						</span>
						<InputText
							value={ritNumber}
							onChange={(e) => setRitNumber(e.target.value)}
							disabled={
								!division ||
								Object.values(storeSelectionList || {}).every(
									(isSelected) => !isSelected
								) ||
								!selectedServerType ||
								!service ||
								!service.length > 0 ||
								!selectedCommand ||
								!jobType
							}
						/>
					</div>

					<>
						<Tooltip target=".suppression-log-info-icon" />
						<i
							className="pi pi-info-circle suppression-log-info-icon"
							// data-pr-tooltip="Please Note: Impact on the stores will be based on the available servers"
							data-pr-tooltip="Please Note:
              Remote command actions will be performed on the stores, servers only if that service is applicable."
							data-pr-position="right"
						/>
					</>

					{storeSelectionList && (
						<Button
							label={
								!modifyJobDetails ? "Create Job" : "Modify Job"
							}
							className="submit-btn"
							ref={createJobBtnRef}
							onClick={(event) =>
								confirmPopup({
									target: event.currentTarget,
									icon: "pi pi-info-circle",
									message: !modifyJobDetails
										? "Do you want to create this job?"
										: "Do you want to modify this job?",

									accept: () => handleCreateJob(),
								})
							}
							disabled={
								!division ||
								Object.values(storeSelectionList || {}).every(
									(isSelected) => !isSelected
								) ||
								!selectedServerType ||
								(!selectedServerType.length > 0 && !service) ||
								// !service.length > 0 ||
								!selectedCommand ||
								!jobType ||
								!ritNumber
							}
						/>
					)}
				</div>
				<Button
					icon={`pi pi-arrow-${
						isModelContainerExpanded ? "right" : "left"
					}`}
					className="collapse-button"
					onClick={handleToggleModelContainer}
				></Button>
			</div>

			<ConfirmPopup
				visible={showConfirmPopUp}
				onHide={() => setShowConfirmPopUp(false)}
			/>
			<ConfirmDialog />
			<Toast ref={toastRef} position="bottom-center" />
		</div>
	);
};

export default GARemoteCommandCreateJob;
